import { useMutation } from '@apollo/react-hooks';
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { pretty } from 'react-native-helpers';
import { LandingTemplate } from './components/LandingTemplate';
import { VERIFY_EMAIL } from './mutation';
import Alert from 'rsuite/lib/Alert';
import axios from 'axios';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import {useHistory, useLocation, Prompt} from 'react-router-dom';
// import {Verifier} from '../LandingPage/components/VerifierPage';
import {VerificationSuccess} from './components/VerificationSuccess';
import { VerificationFailure } from './components/VerificationFailure';
import {PageNotFound} from './components/NotFoundPage';

import config from '../../config/default.config.json';

const { verifyEP, apiEP, httpHost } = config?.servers?.http;

const console: LoggerService = new LoggerService(
  new ConsoleLogger('LandingPage:container'),
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LandingPage = () => {
  const [VerifyEmail, { data, loading, error, called }] = useMutation(VERIFY_EMAIL);
  const history = useHistory();
  const query = useQuery(); 
  
  useEffect(() => {
    if (query.get('email') && query.get('token')) {
      VerifyEmail({
        variables: {
          credential: {
            email: query.get('email'),
            token: query.get('token'),
          },
        },
      });
      var uri = window.location.toString();
      if (uri.indexOf('?') > 0) {
        var clean_uri = uri.substring(0, uri.indexOf('?'));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
  }, []);

const _renderBody = () => {
  if(loading){
    return <LogoSpinnerV />
  }
  if(data){
    return <VerificationSuccess />
  }
  if(error){
    return <VerificationFailure />
  }
  else {
    return <PageNotFound />
  }    
  };
  return <>{_renderBody()}</>;
};
