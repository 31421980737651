import React, { useEffect, useState } from 'react';
import { ResetPasswordPage } from './components';
import {provResetPassword} from './provider';
import {LogoSpinnerV} from 'react-logo-spinner-ext'
import { Alert } from 'rsuite';

export const ResetPassword = () => {
    const [email, setEmail] = useState<string>();
    const [token, setToken] = useState<string>();
    const {PasswordReset, resetPasswordData, resetPasswordLoading, resetPasswordError} = provResetPassword();

    useEffect(()=> {
        const url = window.location.href;
        _getVerifyData(url);
    }); 

    useEffect(()=>{
        if(resetPasswordData){
            Alert.success("Reset Password Successful, please login from app", 10000);
        }
        if(resetPasswordError){
            Alert.error("Reset Password Failed please try Again", 5000);
        }
    }, [resetPasswordData, resetPasswordError]);

    const onResetPassword =(password:string)=>{
        console.log('on reset password pressed', password);
        PasswordReset({
            variables : {
                email: email,
                password:password,
                passwordResetToken: token
            }
        });
    }

    const _getVerifyData = (url: string) => {
        // setUrl(url);
        let params = url.split("/").slice(-2);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const token = urlParams.get('token');
        if(email && token){
            setEmail(email);
            setToken(token);
        }
        console.log('params', token);
    }

    const _renderBody = () => {
        if(resetPasswordLoading){
            return <LogoSpinnerV />
        }
        else {
            return (
                <>
                    <ResetPasswordPage 
                    onResetPassword={onResetPassword}
                    resetPasswordData={resetPasswordData}
                    />
                </>
            )
        }        
    }

    return (
        <>
            {_renderBody()}
        </>
    )
}