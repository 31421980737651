import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ResetPasswordLG } from './ResetPassword.lg';

export interface IProps {
    onResetPassword?(password?: string): void;
    resetPasswordData?:any;
}

export const ResetPasswordPage = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //   return <div />;
    // }

    // if (bp === 'md') {
    //   return <div />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <ResetPasswordLG {...props} />;
};
