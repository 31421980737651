import React, { useContext, useState } from 'react';
import { ResetPasswordV } from 'react-reset-password';
import { ThemeContext } from '../../../providers/theme';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import blueprint from '../config/default-bluprint.json';
import Alert from 'rsuite/lib/Alert';
import {View, Text} from 'react-native';
import { IProps } from './index';
import Icon from 'rsuite/lib/Icon';
const BP = blueprint;
let formContext = {};

export const ResetPasswordLG = (props: IProps) => {
    const [validateForm, setValidateForm] = React.useState<any>(null);
    const theme = useContext(ThemeContext);
    const [date, setDate] = React.useState<Date>(new Date());
    const history = useHistory();
    React.useEffect(() => {
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    const handleValidateResult = (
        formContext: any,
        status: 'success' | 'error',
        validateResult: any,
    ) => {
        console.log('defaultValuesDS', defaultValuesDS.get());

        if (status === 'success') {
            //console.log('forgotPassword data collected successful', defaultValuesDS.get('emailAddress')?.defaultValue,);
        const password = defaultValuesDS.get('password')?.defaultValue;
        const cnfPassword = defaultValuesDS.get('confirmPassword')?.defaultValue;
        if(password === cnfPassword){
            props.onResetPassword &&
            props.onResetPassword(
                defaultValuesDS.get('password')?.defaultValue,
            );
            defaultValuesDS.clear();
        }
        else {
            Alert.error("Password doesn't match please try again", 5000);
        }
        }
        if (status === 'error') {
            BP.forgotPassordErr && Alert.error(BP.forgotPassordErr, 10000);
        }
        setDate(new Date());
        setValidateForm(false);
    };

    const onHeaderLogoPress = () => {
        history.push('/')
    }
    if(props?.resetPasswordData){
        return(
            <View style={{justifyContent:'center', alignItems:'center', marginTop:'15%'}}>
            <Icon
            icon={'ok-circle'}
            size={"5x"}
            style={{color:'green'}}
            />
            <Text style={{padding:10, fontSize:18 }}>
                {BP?.successText}
            </Text>
        </View>
        )
    }
    return (
        <>
            <ResetPasswordV
                containerStyle={{ width: '100%', height: '100vh' }}
                title={BP?.title}
                leftAvatar={BP?.leftAvatar}
                resetButtonColor={theme?.palette?.primary}
                bodyTitle={BP?.bodyTitle}
                formFields={BP?.formFields}
                formContext={formContext}
                validateForm={validateForm}
                defaultValues={defaultValuesDS.get()}
                onHeaderLogoPress={onHeaderLogoPress}
                onValidateResult={handleValidateResult}
                onResetPassword={() => {
                    setValidateForm({
                        ...formContext,
                    });
                }}
                waveColor={theme?.palette?.primary}
                loading={false}
            />
        </>
    )
}